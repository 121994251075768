@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");
* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

body {
    /* font-family: "Roboto", "Helvetica", "Arial", sans-serif; */
    font-family: sans-serif;
}
header {
    width: 100%;
    height: auto;
}
.customLogo{
    font-weight:700;
    font-family: "league spartan";
    align-items: center;
    color: #f2a732;
}
div#navbarSupportedContent ul li a {
    color: #000;
    font-weight:500;
}
.customBotton a{
    color: #fff !important;
    background-color: #f2a732;
    border-radius: 8px;
}


  

/*------------------ Banner Section ----------------------------*/
.showcase {
    width: 100%;
    height: 500px;
    position: relative;
    color: white;
    text-align: center;
  }
  
  .showcase img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index:99
  }
  
  .showcase .overlay {
    width: 100%;
    height: 500px;
    background-color:#6FB6B661;
    position: absolute;
    top: 0;
    left: 0;
    z-index:999
  }
  
  .showcase h2 {
    margin-top: 130px;
    font-size: 3em;
  }
  
  .showcase p {
    margin-top: 8px;
    font-size: 1.2em;
  }

  /*-------------------------------- AllService----------------------*/

.forClient{
    width: 100%;
    height: auto;  
}
.forClientText h1{
   text-align: center;
}
.forClientBox{
    width: 100%;
    height: auto;
    display: flex;
    /* flex-wrap: wrap; */
    justify-content: space-around;
}
.AllserviceBox1{
    width: 200px;
    height: auto;
    border-radius: 8px;
  
    color: #fff;
    text-align: center;
}
.forClientBox1 h6{
    font-weight: 600;
    margin-top: 40px;
    margin-bottom: 60px;
}
.forClientBox1 p{
    margin-bottom: 30px;
}

.forClientBox1 img{
    background-color: #d5d2d3;
    padding: 15px;
    border-radius: 50px;
}


  



/*------------------- Services Part  ------------------------------*/

.servicesPart{
    width: 100%;
    height: auto;
}
.allservicesPart{
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
    justify-content: space-around;
}
.servicesPart1{
    width: 150px;
    height: 150px;
    border: 1px solid #000;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;    
}
.servicesPart1 i{
    color: #79e7bf;
    font-size: 25px;
    margin-top: 20px;
}

/*--------------------------- companyLogo Section -------------------------------*/

.companyLogo{
    width: 100%;
    height: auto;
}
.trustedby p{
    text-align: center;
    font-size: 18px;
    font-weight: 500;
}
.allcompanylogo{
    display: flex;
    flex-wrap: wrap;
    padding-top: 10px;
    padding-bottom: 50px;
    justify-content: space-between;
}
.allcompanylogo1{
    width: 200px;
    height: 100px;
    /* border: 1px solid red; */
}
.allcompanylogo1 img{
    display: flex;
    align-items: center;
}

/*-------------------------------- enterpriseSuite Section -------------------------*/

.enterpriseSuite{
    width: 100%;
    height: auto;
}
.leftSection{
    width: 100%;
    height: auto;
  
}
.leftSection h5{
    color: #000000;
    padding-top: 15px;
    padding-left: 20px;
    padding-bottom: 25px;
    font-size: 15px;
}
.leftSection h3{
    color: #230fda;
    padding-left: 20px;
}
.leftSection .customH3{
    color: #3d24c9 !important;
}
.leftSection p{
    padding-left: 20px;
    color: #000000;
    font-size: 15px;
    padding-top: 20px;
    padding-bottom: 12px;
}
.leftSection ul li{
    color: #000000;
    font-size: 15px;
}
.leftSection ul{
    margin-left: 22px;
    padding-bottom: 48px;
}
.rightSection{
    width: 100%;
    height: auto;
}
.rightSection img{
    width: 100%;
    height: 400px;
}

/*------------------------------- popularServices Part ---------------------*/

.popularServices{
    width: 100%;
    height: auto;
}
.allpopularServices{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.popularServices1{
    width: 190px;
    height: auto;
  
    padding: 10px;
    border-radius: 10px;
}
.popularServices1 h6{
    margin-top: 15px;
    margin-bottom: 20px;
    margin-left: 12px;
    font-size: 16px;
    color: #fff;
}
.popularServices1 img{
    padding: 10px;
    border-radius: 20px;
    border: 3px solid #fff;
    margin-bottom: 10px;
}

.popularServices2{
    width: 190px;
    height: auto;
  
    padding: 10px;
    border-radius: 10px;
}
.popularServices2 h6{
    margin-top: 15px;
    margin-bottom: 20px;
    margin-left: 12px;
    font-size: 16px;
    color: #fff;
}
.popularServices2 img{
    padding: 10px;
    border-radius: 20px;
    border: 3px solid #fff;
    margin-bottom: 10px;
    height: 125px;
    width: 100%;
}


.popularServices3{
    width: 190px;
    height: auto;
 
    padding: 10px;
    border-radius: 10px;
}
.popularServices3 h6{
    margin-top: 15px;
    margin-bottom: 20px;
    margin-left: 12px;
    font-size: 16px;
    color: #fff;
}
.popularServices3 img{
    padding: 10px;
    border-radius: 20px;
    border: 3px solid #fff;
    margin-bottom: 10px;
}

.popularServices4{
    width: 190px;
    height: auto;
    background-color: #57a3cb;
    padding: 10px;
    border-radius: 10px;
}
.popularServices4 h6{
    margin-top: 15px;
    margin-bottom: 20px;
    margin-left: 12px;
    font-size: 16px;
    color: #fff;
}
.popularServices4 img{
    padding: 10px;
    border-radius: 20px;
    border: 3px solid #fff;
    margin-bottom: 10px;
    margin-top: 18px;
    width: 100%;
    height: 128px;
}

.popularServices5{
    width: 190px;
    height: auto;

    padding: 10px;
    border-radius: 10px;
}
.popularServices5 h6{
    margin-top: 15px;
    margin-bottom: 20px;
    margin-left: 12px;
    font-size: 16px;
    color: #fff;
}
.popularServices5 img{
    padding: 10px;
    border-radius: 20px;
    border: 3px solid #fff;
    margin-bottom: 10px;
    width: 100%;
    height: 130px;
}

/*---------------------------- talentFind Part ---------------------------------*/

.talentFind{
    width: 100%;
    height: auto;
    margin-top: 30px;
    background-image:"url(../images/midBackground.png)";
    background-repeat: no-repeat;
    background-size: cover;
    /* background-origin: content-box; */
    /* background-origin: content-box, padding-box; */
}
.midBackground{
  text-align: center;
}
.midBackground h2{
    color: #ffd200;
    margin-top: 100px;
    margin-bottom: 100px;
}
.midBackground h6{
    color: #fff;
    line-height: 1.5;
    font-size: 18px;
    padding-bottom: 250px;
}

/*-------------------------------- forClient part ----------------------*/

.forClient{
    width: 100%;
    height: auto;  
}
.forClientText h1{
   text-align: center;
}
.forClientBox{
    width: 100%;
    height: auto;
    display: flex;
    /* flex-wrap: wrap; */
    justify-content: space-around;
}
.forClientBox1{
    width: 290px;
    height: auto;
    border-radius: 8px;
   
  
    text-align: center;
}
.forClientBox1 h6{
    font-weight: 600;
    margin-top: 40px;
    margin-bottom: 60px;
}
.forClientBox1 p{
    margin-bottom: 30px;
}

.forClientBox1 img{
  
    padding: 15px;
    border-radius: 50px;
}

/*----------------------------------- forTalent Part ---------------------------*/

.forTalent{
    width: 100%;
    height: auto;
    margin-top: 50px;
}

.talentRightPart h5{
    padding-left: 20px;
    padding-top: 25px;
    padding-bottom: 30px;
}
.talentRightPart h1{
    padding-left: 20px;
    padding-bottom: 35px;
    font-size: 32px;
    font-weight: 600;
}
.talentRightPart p{
    padding-left: 20px;
    padding-bottom: 20px;
}
.horizontalLine{
    width: 490px;
    height: 1px;
    background-color: #fff;
    margin-left: 20px;
}
.threeColumn{
    display: flex;
    /* flex-wrap: wrap; */
    justify-content: center;
}
.threeColumn1{
    width: 150px;
    height: auto;
    /* border: 1px solid red; */
    margin-right: 25px;
}
.forButton{
    padding-bottom: 50px;
    display: flex;
    margin-top: 10px;
}
.forButton a{
    text-decoration: none;
    background-color: #ff9119;
    color: #fff;
    padding: 8px 30px 8px 30px;
    border-radius: 10px;
    font-weight: bold;
    margin-left: auto;
    margin-right: auto;
}

/*________________________________ timandDan Part _____________________________________ */

.timandDan{
    width: 100%;
    height: auto;
    margin-top: 50px;
}
.timandDanLeft{
    background-color: #f6f6f6;
}
.timandDanLeft h5{
    font-weight: 600;
    font-size: 18px;
    padding-top: 110px;
    padding-bottom: 15px;
    margin-left: 25px;
}
.timandDanLeft p{
    margin-left: 25px;
    font-style: italic;
    padding-bottom: 300px;
}
.timandDanRight{
    background-color: #f6f6f6;
}
.timandDanRight h6{
    padding-top:50px;
    font-weight: 600;
    font-size: 18px;
    padding-bottom: 30px;
}
.timandDanRight img{
    width: 510px;
    padding-bottom: 155px;
}

/*_________________________  Made on Silver oak part ______________________________*/

.madeonPart{
    width: 100%;
    height: auto;
}

.madeonImg1 img {
    width: 250px;
    height: 200px;
    padding-bottom: 25px;
}
.madeonImg11 img{
    width: 244px;
    height: 400px;
}

.madeonImg2 img {
    width: 250px;
    height: 200px;
    padding-bottom: 25px;
}
.madeonImg22 img{
    width: 250px;
    height: 400px;
}

.madeonImg3 img {
    width: 250px;
    height: 200px;
    padding-bottom: 25px;
}
.madeonImg33 img{
    width: 259px;
    height: 400px;
}

.madeonImg4 img {
    width: 250px;
    height: 200px;
    padding-bottom: 25px;
}
.madeonImg44 img{
    width: 250px;
    height: 400px;
}

/*_______________________  Door Step of Freelance services !  Start ______________________*/


.doorStep{
    width: 100%;
    height: auto;
    margin-top: 80px;
    
}
.doorStepBack{
    background-color: #5d96ed;
}
.doorStepof h5{
    color: #fff;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 50px;
}
.doorStepofbtn{
    padding-bottom: 50px;
    display: flex;
    margin-top: 10px;
}
.doorStepofbtn a{
    text-decoration: none;
    background-color:#f7a528;
    color: #fff;
    padding: 8px 40px 8px 40px;
    border-radius: 10px;
    /* font-weight: bold; */
    margin-left: auto;
    margin-right: auto;
}
.footerPart{
    width: 100%;
    height: auto;
    margin-top: 80px;
}
.footerPartHead h5{
    font-weight: 600;
    font-size: 22px;
    padding-bottom: 20px;
}
.footerPartPara ul li{
    list-style: none;
    margin-top: 8px;
    margin-left: -30px;
}
.footerPartPara ul li a{
    text-decoration: none;
    color: #ffffff;
}
.footerAbout ul li{
    list-style: none;
    margin-top: 8px;
    margin-left: -30px;
}
.footerAbout ul li a{
    text-decoration: none;
    color: #ffffff;
}
.footerSupport ul li{
    list-style: none;
    margin-top: 8px;
    margin-left: -30px;
}
.footerSupport ul li a{
    text-decoration: none;
    color: #ffffff;
}
footer{
    margin-bottom: 100px;
}

/*----------- Media Query Start --------------*/

@media (max-width: 576px) {
    .bannerPart{
        background-color: #5898c6;
        width: 100%;
        height: auto;
        padding-bottom: 80px;
        /* background-image: url(../images/bannerBack.png);
        background-repeat: no-repeat;
        background-size: cover;
        background-position-y: 0px; */
    }
    #searchQueryInput {
        width: 76%;
    }
    .allservicesPart {
        display: flex;
        flex-wrap: wrap;
        padding: 20px;
        justify-content: space-around;
        flex-direction: column;
        align-items: center;
    }
    .servicesPart1 {
    margin-bottom: 20px;
    }
    .allcompanylogo {
        flex-direction: column;
        align-items: center;
    }
    .rightSection img {
        margin-top: -16px;
    }
    .allpopularServices {
        flex-direction: column;
        align-items: center;
    }
    .popularServices1 {
        margin-bottom: 15px;
    }
    .popularServices2 {
        margin-bottom: 15px;
    }
    .popularServices3 {
        margin-bottom: 15px;
    }
    .popularServices4 {
        margin-bottom: 15px;
    }
    .popularServices5 {
        margin-bottom: 15px;
    }
    .forClientBox {
        flex-wrap: wrap;
    }
    .forClientBox1 {
        margin-bottom: 15px;
    }
    .horizontalLine {
        width: 415px;
    }
    .timandDanLeft h5 {
        font-weight: 600;
        font-size: 18px;
        padding-top: 48px;
        padding-bottom: 20px;
        margin-left: 25px;
    }
    .timandDanLeft p {
        margin-left: 25px;
        padding-bottom: 40px;
    }
    .timandDanRight h6 {
        margin-left: 25px;
    }
    .timandDanRight img {
        padding-bottom: 35px;
    }
    .searchBar {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
    .madeonImg1 {
        text-align: center;
    }
    .madeonImg2 {
        text-align: center;
    }
    .madeonImg3 {
        text-align: center;
    }
    .madeonImg4 {
        text-align: center;
    }
    li.nav-item.customBotton {
        width: 75px;
    }
    a.nav-link.customJoin {
        padding-left: 10px;
    }
}

/* ____________________________________________ explore product slider _____________________________ */

@import url(http://netdna.bootstrapcdn.com/font-awesome/4.0.3/css/font-awesome.min.css);
.col-item
{
    border: 1px solid #E1E1E1;
    border-radius: 5px;
    background: #FFF;
}
.col-item .photo img
{
    margin: 0 auto;
    width: 100%;
}

.col-item .info
{
    padding: 10px;
    border-radius: 0 0 5px 5px;
    margin-top: 1px;
}

.col-item:hover .info {
    background-color: #F5F5DC;
}
.col-item .price
{
    /*width: 50%;*/
    float: left;
    margin-top: 5px;
}

.col-item .price h5
{
    line-height: 20px;
    margin: 0;
}

.price-text-color
{
    color: #219FD1;
}

.col-item .info .rating
{
    color: #777;
}

.col-item .rating
{
    /*width: 50%;*/
    float: left;
    font-size: 17px;
    text-align: right;
    line-height: 52px;
    margin-bottom: 10px;
    height: 52px;
}

.col-item .separator
{
    border-top: 1px solid #E1E1E1;
}

.clear-left
{
    clear: left;
}

.col-item .separator p
{
    line-height: 20px;
    margin-bottom: 0;
    margin-top: 10px;
    text-align: center;
}

.col-item .separator p i
{
    margin-right: 5px;
}
.col-item .btn-add
{
    width: 50%;
    float: left;
}

.col-item .btn-add
{
    border-right: 1px solid #E1E1E1;
}

.col-item .btn-details
{
    width: 50%;
    float: left;
    padding-left: 10px;
}
.controls
{
    margin-top: 20px;
}
[data-slide="prev"]
{
    margin-right: 10px;
}

/*________________________________________ Explore Page Css Start Menubar ________________________ */

.upHeader{
    width: 100%;
    height: auto;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Google Fonts - Poppins */
/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");
* {
margin: 0;
padding: 0;
box-sizing: border-box;
font-family: "Poppins", sans-serif;
} */

.nav {
/* position: fixed; */
top: 0;
left: 0;
width: 100%;
/* padding: 15px 200px; */
background: #fff;
/* box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); */
}
.nav,
.nav .nav-links {
display: flex;
align-items: center;
}
.nav {
justify-content: space-around;
}
a {
color: #353842;
text-decoration: none;
}
.nav .logo {
font-size: 22px;
font-weight: 500;
}
.nav .nav-links {
column-gap: 20px;
list-style: none;
margin-top: 12px;
}
.nav .nav-links a {
transition: all 0.2s linear;
}
.nav.openSearch .nav-links a {
opacity: 0;
pointer-events: none;
}
.nav .search-icon {
color: #353842;
font-size: 20px;
cursor: pointer;
}
/* .nav .search-box {
position: absolute;
right: 250px;
height: 45px;
max-width: 555px;
width: 100%;
opacity: 0;
pointer-events: none;
transition: all 0.2s linear;
} */
.nav .search-box {
    position: absolute;
    right: 125px;
    height: 45px;
    max-width: 555px;
    width: 100%;
    opacity: 0;
    pointer-events: none;
    transition: all 0.2s linear;
}

.nav.openSearch .search-box {
opacity: 1;
pointer-events: auto;
}
.search-box .search-icon {
position: absolute;
left: 15px;
top: 50%;
left: 15px;
/* color: #ffcc00; */
transform: translateY(-50%);
}
.search-box input {
height: 100%;
width: 100%;
border: none;
outline: none;
color: white;
border-radius: 6px;
background-color: #353842;
padding: 0 15px 0 45px;
}
.nav .navOpenBtn,
.nav .navCloseBtn {
display: none;
}
/* responsive */
@media screen and (max-width: 1160px) {
.nav {
padding: 15px 100px;
}
.nav .search-box {
right: 150px;
}
}
@media screen and (max-width: 950px) {
.nav {
padding: 15px 50px;
}
.nav .search-box {
right: 100px;
max-width: 400px;
}
}
@media screen and (max-width: 768px) {
.nav .navOpenBtn,
.nav .navCloseBtn {
display: block;
}
.nav {
padding: 15px 20px;
}
.nav .nav-links {
position: fixed;
top: 0;
left: -100%;
height: 100%;
max-width: 280px;
width: 100%;
padding-top: 100px;
row-gap: 30px;
flex-direction: column;
background-color: #2596be;
box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
transition: all 0.4s ease;
z-index: 100;
}
.nav.openNav .nav-links {
left: 0;
}
.nav .navOpenBtn {
color: #353842;
font-size: 20px;
cursor: pointer;
}
.nav .navCloseBtn {
position: absolute;
top: 20px;
right: 20px;
color: #353842;
font-size: 20px;
cursor: pointer;
}
.nav .search-box {
/* top: calc(100% + 10px); */
top: calc(90px);
max-width: calc(100% - 20px);
right: 50%;
transform: translateX(50%);
box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
}

.orderRound a{
    border: 1px solid #000;
    padding: 8px 18px 8px 18px;
    border-radius: 8px;
}
.roundImg a{
    /* border: 1px solid #000; */
    padding: 8px 12px 8px 12px;
    border-radius: 50px;
    background-color: #2596be;
    color: #fff;
}
/* .nav .search-box {
    position: absolute;
    right: 328px !important;
    height: 45px;
    max-width: 555px;
    width: 100%;
    opacity: 0;
    pointer-events: none;
    transition: all 0.2s linear;
}

.nav .search-box {
    position: absolute;
    right: 250px;
    height: 45px;
    max-width: 555px;
    width: 100%;
    opacity: 0;
    pointer-events: none;
    transition: all 0.2s linear;
} */

.search-box input {
    height: 100%;
    width: 100%;
    border: 1px solid #000;
    outline: none;
    color: white;
    border-radius: 30px;
    background-color: #fff;
    padding: 0 15px 0 45px;
}
.allservicesPartExplore{
    display: flex;
    flex-wrap: wrap;
    padding: 5px;
    justify-content: space-around;
}


/* ______________________________________ Testimonial part start _____________________ */

.testimonial{
    width: 100%;
    height: auto;
    margin-top: 60px;
}

.section-testmonials {
    font-family: Poppins;
    display: flex;
    gap: 20px;
    align-items: center;
}

.column-testmonials {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 20%;
}

.section-details {
    border-left: 5px solid #4c739c;
    padding-left: 20px;
}


.name-section {
    font-weight: 800;
    text-transform: uppercase;
    font-size: 14px;
    color: #650061 !important;
    line-height: 1.2em;
    margin: 0;
}

.title-section b {
    color: #650061;
}

.title-section {
    color: #000;
    font-family: Poppins;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
}

.navigation-testmonials {
    display: flex;
    gap: 10px;
}


.swiper-testmonials {
    width: 80%;
    overflow: hidden;
    position: relative;
}

@media (max-width: 768px) {
    .section-testmonials {
        flex-direction: column;
        align-items: flex-start;
    }

    .swiper-testmonials {
        width: 100%;
    }
}

.swiper-testmonials .swiper-slide {
    border-radius: 10px;
    /* background: linear-gradient(118deg, #0a4773 10.88%, #4a759e 83.88%); */
    background: linear-gradient(118deg, #4a759e 10.88%, #0a4773 83.88%);
    display: flex;
    padding: 30px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    transform: scale(0.9) !important;
    filter: blur(1px) grayscale(0.5);
    transition: 0.2s;
}

.swiper-testmonials .swiper-slide * {
    margin: 0;
}

.swiper-testmonials .swiper-slide-active {
    filter: blur(0px) !important;
    transform: scale(1) !important;
}

.card-slide {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.head-slide {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header-slide {
    display: flex;
    align-items: center;
    gap: 20px
}

.title-slide h4 {
    color: #FFF;
    font-size: 20px;
    font-weight: 700;
}

.title-slide h6 {
    color: #FFCB00;
    font-size: 14px;
    font-weight: 500;
}

.text-slide {
    color: #FFF;
    font-family: Poppins;
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
}

.swiper-button-testmonials-next,
.swiper-button-testmonials-prev {
    cursor: pointer;
}


@media (max-width: 768px) {
    .header-slide svg {
        width: 50px;
    }

    .title-slide h4 {
        font-size: 14px;
    }

    .swiper-testmonials .swiper-slide {
        padding: 20px;
    }
}

.customIconTest i{
    color: #fff;
    background-color: #34495e;
    padding: 25px;
    border-radius: 50px;
    font-size: 15px;
}
.customIconTest1 i{
    color: #fff;
    background-color: #aad7df;
    padding: 25px;
    border-radius: 50px;
    font-size: 15px;
}
.customIconTest2 i{
    color: #000;
    background-color: #fff;
    padding: 25px;
    border-radius: 50px;
    font-size: 15px;
}

/*  ___________________________ browsing history Start __________________________________*/

.browserHistory{
    width: 100%;
    height: auto;
}

/* ________________________   keepExploring Start  _______________________________*/

.keepExploring{
    width: 100%;
    height: auto;
}

/* _______________________ Based on your browsing history neeche ka slider part ______________*/

.maySlider{
    width: 100%;
    height: auto;
}
.maySliderImg1{
    width: 100%;
    height: auto;
    border-radius: 8px;
}
.maySliderImg1 img{
    border-radius: 8px;
}
.maySliderText ul li{
    list-style: none;
    display: inline;
}
.maySliderText img{
    margin-left: -30px;
}


.maySliderImg2{
    width: 100%;
    height: auto;
    border-radius: 8px;
}
.maySliderImg2 img{
    border-radius: 8px;
}
.maySliderImg3{
    width: 100%;
    height: auto;
    border-radius: 8px;
}
.maySliderImg3 img{
    border-radius: 8px;
}
.maySliderImg4{
    width: 100%;
    height: auto;
    border-radius: 8px;
}
.maySliderImg4 img{
    border-radius: 8px;
}
.maySliderImg5{
    width: 100%;
    height: auto;
    border-radius: 8px;
}
.maySliderImg5 img{
    border-radius: 8px;
}




/*______________________ Second Page Start_____________________________*/

.secondLayout{
    width: 100%;
    height: auto;
}
.leftSides{
    width: 100%;
    height: auto;
}
.leftSides h1{
    font-weight: 700;
    font-size: 32px;
    margin-bottom: 30px;
}
.leftSides a{
    font-weight: 700;
    text-decoration: underline;
    margin-top: -10px;
}
.leftSides a:hover{
    color: #000;
}
.leftSides img{
    margin-top:40px;
}
.rightSidesTxt h6{
    font-weight: 600;
    font-size: 15px;
}
.rightSidesTxt p{
    font-size: 14px;
    color: #6c757d;
}
.rightHyper a{
    color: #000;
    text-decoration: underline;
}
.rightSidesTxt3 h6{
    font-weight: 600;
    font-size: 15px;
}
.rightSidesTxt3 p{
    font-size: 14px;
    color: #6c757d;
}
.howToWrite a{
    color: #000;
    text-decoration: underline;
}
.rightSidesTxt3 h6{
    font-weight: 600;
    font-size: 15px;
}
input[type="radio"] {
    margin-right: 10px;
}
.manyBotton button.btn.btn-secondary {
    background-color: gray !important;
    border: none;
    margin: 10px;
}

input::placeholder {
    font-weight: bold;
    opacity: 0.5;
    
  }
  

/* _____________________________ Popup Box Start ___________________________________*/


.popup-box {
    width: 60%;
    /* background-color: #eee; */
    padding: 20px;
    /* font-size: 36px; */
    /* text-align: center; */
    position: absolute;
    top: 68%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    display: none;
}

.popup-box .span {
    position: absolute;
    top: -10px;
    right: -10px;
    width: 20px;
    height: 20px;
    background-color: red;
    color: white;
    padding: 5px;
    border-radius: 50%;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.fullSignUpBox{
    width: 100%;
    height: auto;
    display: flex;
    /* flex-wrap: wrap; */
    /* flex-direction: row; */
    border-radius: 15px;
}
.fullSignUpBox1{
    background-color: #ffa199;
    width: 100%;
}
.fullSignUpBox1 img{
    width: 422px;
    height: 350px;
    margin-top: 30px;
}
.fullSignUpBox1 h5{
    margin-top: 20px;
    margin-left: 20px;
    font-weight: 600;
    color: #fff;
}
.fullSignUpBox1 ul{
    margin-left: 15px;
    margin-top: 20px;
    margin-bottom: 15px;
}
.fullSignUpBox1 ul li{
    color: #fff;
}
.fullSignUpBox2{
    background-color: #fff;
    width: 100%;
}
.fullSignUpBoxTxt{
    text-align: center;
}
.fullSignUpBoxTxt h5{
    font-weight: 700;
    margin-top: 30px;
    margin-bottom: 20px;
}
.fullSignUpBoxTxt p{
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 40px;
}
/* .fullSignUpBoxForm .cusAbsolute{
    position:static !important;
    color: #000;
} */
a.btn.btn-lg.btn-outline-dark:hover {
    background: none;
    color: #000;
}
a.btn.btn-lg.btn-outline-dark {
    width: 350px;
    align-items: center;
    display: flex;
    margin: auto;
    border-radius: 15px;
}
.fullSignUpBoxFormOR h6 {
    margin-top: 30px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 30px;
}

.fullSignUpBoxFormSocial a.btn.btn-lg.btn-outline-dark {
    width: 150px;
    align-items: center;
    display: flex;
    margin: auto;
    border-radius: 15px;
}
.fullSignUpBoxFormSocial a.btn.btn-lg.btn-outline-dark {
    width: 150px;
    align-items: center;
    display: flex;
    margin: auto;
    border-radius: 15px;
    justify-content: space-around;
}
.fullSignUpBoxP p{
    font-weight: 600;
    margin-top: 80px;
    text-align: center;
    font-size: 12px;
}
/*--------input field calender css--------*/
#calendar {
    font-family: Arial, sans-serif;
   padding-top: 50px;
    border-radius: 5px;
    
    width: 700px;
}


/*____________ my about pages css ____________*/

.customColr{
    background-color: #0ec244 !important;
    margin-left: 10px;
    padding: 2px 50px 2px 9px !important;
    border: none;
}
.customRatingClr{
    background-color: #f2a72e;
    border: none;
    padding: 2px 12px 2px 12px !important;
    margin-left: 10px;
}
.customRatingClr:hover{
    background-color: #f2a72e;
}
.customIconclr{
    color: #f2a72e;
}
.centerImg img{
    display: flex;
    margin: auto;
    margin-top: 10px;
}
.cusBtnclr{
    background-color: #f8a52b;
}
.cusBtnclr1{
    background-color: #036db8;
}

.rightReviewClient{
    margin-top: 200px;
    border-left: 2px solid gray;
}
.rightReviewClient1{
    margin-top: 150px;
    border-left: 2px solid gray;
}
.rightReviewClientBox{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border: 1px solid gray;
    border-radius: 15px;
    padding: 15px;
}
.rightReviewClientBoxImg img{
    border-radius: 15px;
}
.rightReviewClientBoxImgDetails{
    display: flex;
    justify-content: center;
}
.workExperincePart2{
    display: flex;
    flex-direction: row;
}
.workExperincePartImgTxt p{
    color: gray;
}

/*__________________________________ Professional.html page start csss _______________*/

.mandatoryField p{
    font-weight: 700;
    font-size: 12px;
    margin-top: 150px;
    text-align: end;
}
.occupationPartA a{
    font-size: 13px;
    color: #078deb;
    margin-left: 120px;
}
.skillsPart{
    width: 100%;
    height: auto;
}
.skillsPart1{
    margin-top: 15px;
}
.skillsPart1 h6{
    font-weight: 600;
    float: left;
    clear: both;
}
.skillsPart1 i {
    font-size: 6px;
    color:#a52e36;
    margin-top: 2px;
    position: absolute;
}
.skillsPart2{
    display: flex;
    padding: 8px;
    border: 2px solid #437090;
    align-items: center;
    justify-content: space-around;
}
select#course {
    padding: 5px 25px 6px 25px;
    background: #d9d9d9;
}
.skillsSection3 a{
    padding: 5px;
    border: 1px solid #437090;
    background: #d9d9d9;
    padding: 5px 25px 6px 25px;
    text-decoration: none;
}
.skillsSection3 a:hover{
    color: #fff;
    background:#026ebf;
}
.skillsSection4 a{
    padding: 5px;
    border: 1px solid #437090;
    background:#026ebf;
    padding: 5px 25px 6px 25px;
    text-decoration: none;
    color: #fff;
}
.skillsSection4 a:hover{
    color: #000;
    background: #d9d9d9;
}
.profilePart{
    width: 100%;
    height: auto;
}
.profilePart1 h6{
    font-weight: 600;
}

table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
     width: 100%;
    /*width: 207%;*/
  }
  
  td, th {
    border: 2px solid #b8b9ba;
    text-align: left;
    padding: 8px;
  }
  
  tr:nth-child(odd) {
    background-color: #dddddd;
  }
.profilePart2 td{
    background-color: #fff;
}

/* ___________________  ___________________________*/


@media (max-width: 768px) {
        table {
            font-family: arial, sans-serif;
            border-collapse: collapse;
            width: 100%;
    }
    select#course {
        padding: 5px 10px 6px 10px;
        background: #d9d9d9;
    }
    .skillsSection3 a {
        padding: 5px;
        border: 1px solid #437090;
        background: #d9d9d9;
        padding: 5px 10px 6px 10px;
        text-decoration: none;
    }
    .skillsSection4 a {
        padding: 5px;
        border: 1px solid #437090;
        background: #026ebf;
        padding: 5px 10px 6px 10px;
        text-decoration: none;
        color: #fff;
    }
}

/*________________________ gig Page ______________________________*/

.gigPageSection{
    width: 100%;
    height: auto;
    border-top: 1px solid gray;
}
.bgColorr{
    background-color: #2046fb;
    padding: 0px 4px 0px 4px;
    border-radius: 50px;
    text-align: center;
    color: #fff;
    margin-right: 5px;
}
.bgColorr1{
    background-color:gray;
    padding: 0px 4px 0px 4px;
    border-radius: 50px;
    text-align: center;
    color: #fff;
    margin-right: 5px;
}

ul.breadcrumb{
    padding: 10px 16px;
    list-style: none;
    /* background-color: #f3f3f3; */
  }
  
  ul.breadcrumb li{
    display: inline;
  }
  
  ul.breadcrumb li+li::before{
    padding: 8px;
    color: gray;
    content: ">\00a0";
  }
  
  ul.breadcrumb li a{
    color: #6087a7;
  }
.paginationPart{
    display: flex;
    align-items: center;
}
.customMenu li{
    list-style: none;
    display: inline;
    margin: 8px;
}
.customMenu li a{
    border: 1px solid #000;
    padding: 4px 10px 4px 10px;
    border-radius: 8px;
}
.customMenuu .customMenu{
    margin-left: 150px;
}
.breadcrumb li a{
    font-size: 14px;
}
.breadcrumb li {
    font-size: 14px;
}



.gigPart{
    width: 100;
    height: auto;
    margin-top: 10px;
}
.gigPartText1 h5{
    font-weight: 600;
}
.gigPartText1 p{
    /* padding-right: 35px; */
    /* text-align: justify; */
}
.gigPartMid2{
    display: flex;
    justify-content: space-between;
    padding: 0px 10px 0 10px;
}
.gigPartMid2 a{
    color: #026ebf;
}

.gigPartRight1{
    width: 100%;
    height: auto;
    background-color: #3bad21;
}
.gigPartRight1 h5{
    text-align: center;
    color: #fff;
    font-weight: 600;
    padding-top: 10px;
    padding-bottom: 20px;
}
/*dropdown css*/
.dropdown:hover .dropdown-menu {
    display: block;
  }
 
  /*______________________ Scope & pricing _______________________________*/

  .bgCheck {
    font-size: 25px;
    color: blue;
    display: flex;
    justify-content: center;
    align-items: center;
}
#custWidth{
    width: 150px;
    color:#000;
}

#custWidth2{
    width: 100px;
    color:#000;
    margin-left: 10px;
}

/*--------------talent Seller---------------------*/

.mainDiv{
    width: 100%;
    height: auto;
}
.allCard{
    display: flex;
    flex-wrap: wrap;
}
.customCardBorder{
    border: 1px solid #5893b5;
}
.allItem{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}
.userIcon i{
    
    padding: 28px 25px 28px 25px;
    font-size: 20px;
    background-color: #efefef;
}
.scopic{
    font-size: 15px;
    font-weight: 600;
    color: #026ebf;
    margin-left: 8px;
}
.member{
    font-size: 12px;
    background-color: #efefef;
    padding: 6px 8px 6px 8px;
    font-weight: 600;
    text-transform: uppercase;
    border-radius: 5px;
}
.USACus{
    margin-left: 8px;
    font-size: 12px;
    font-weight: 600;
}
.paragraphDiv a{
    font-size: 12px;
    text-decoration: none;
    background-color: #2549ef;
    padding: 5px;
    color: #fff;
    margin-left: 195px;
    border-radius: 5px;
}
.custDoller{
    font-size: 10px;
    margin-left: 8px;
    background: #000;
    color: #fff;
    padding: 3px 6px;
    border-radius: 10px;
}
.customDoller{
    font-size: 10px;
}
.customPrice{
    font-size: 11px;
    font-weight: 600;
}
.customLike{
    font-size: 11px;
    font-weight: 600;
    margin-left: 10px;
}
.customHeart{
    font-size: 15px;
    margin-left: 205px;
}
.horizontalLine {
    width: 485px;
    height: 1px;
    background-color: #000;
    margin: 0px auto;
    margin-left: 10px;
}
.allItem1{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.imgSection img {
    width: 70px;
    height: 50px;
    margin-left: 18px;
    margin-top: 10px;
}
.custtext1{
    font-size: 13px;
    margin-left: 8px;
    font-weight: 600;
}
.custDoller1{
    font-size: 13px;
    font-weight: 600;
}
.custStart{
    font-size: 13px;
    font-weight: 600;
    margin-left: 45px;
}
.textSectioncus1 p {
    font-size: 10px;
    margin-left: 95px;
    margin-top: -35px;
}


.allAppitem{
    /* display: flex; */
    /* flex-wrap: wrap; */
}
.allAppitem a{
    text-decoration: none;
    font-size: 10px;
    border: 1px solid #000;
    padding: 5px;
    color: #000;
    border-radius: 5px;
}
.allportfolliowItem{
    width: 100%;
    height: auto;
    background-color: #febf62;
}
.allportfolliowItem{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding-bottom:8px;
}
.portfolioItem .portCusIcon{
    font-size: 12px;
}
.portfolioItem .cusMoreService{
    font-size: 12px;
    text-transform: uppercase;
}
.cusMoreService a{
    color: #000;
}
.CustomLine{
    width: 1px;
    height: 15px;
    background-color: #000;
    border: 1px solid #000;
    align-content: center;
    margin-left: 6px;
}

/*----------------------- Load More ____________*/

.lordMoreSection{
    width: 100%;
    height: auto;
}
.lordMoreSection1{
    display: flex;
}
.lordMoreSection1 a{
    padding: 12px 50px 12px 50px;
    border: 1px solid #2549ef;
    text-decoration: none;
    border-radius: 6px;
    color:#000;
    margin: 0px auto;
    margin-bottom: 10px;
    font-weight: 600;
}




@media (max-width: 768px) {
    .allItem{
        flex-direction: row;
        flex-wrap: nowrap;
    }
    .paragraphDiv a {
        font-size: 10px;
        text-decoration: none;
        background-color: #2549ef;
        padding: 5px;
        color: #fff;
        margin-left: 165px;
        border-radius: 5px;
    }
    .member{
        font-size: 10px;
        background-color: #efefef;
        padding: 6px 8px 6px 8px;
        font-weight: 600;
        text-transform: uppercase;
    }
    .scopic {
        font-size: 11px;
        font-weight: 600;
        color: #026ebf;
        margin-left: 8px;
    }
    
    .USACus {
        margin-left: 8px;
        font-size: 10px;
        font-weight: 600;
    }
    /* .paragraphDiv a{
        font-size: 10px;
        text-decoration: none;
        background-color: #2549ef;
        padding: 5px;
        color: #fff;
        margin-left: 165px;
    } */
    .horizontalLine{
        display: none;
    }
    .customHeart {
        font-size: 15px;
        margin-left: 160px;
    }
    .imgSection img {
        width: 65px;
        height: 50px;
        margin-left: 18px;
        margin-top: 10px;
    }
    .custtext1 {
        font-size: 10px;
        margin-left: 8px;
        font-weight: 600;
    }
    .allItem1 {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: 20px !important;
    }
    .professionalServicesPart2{
        display: flex;
        flex-wrap: wrap;
    }
}

/*.mainDiv{
    width: 100%;
    height: auto;
}
.allCard{
    display: flex;
    flex-wrap: wrap;
}
.customCardBorder{
    border: 1px solid #5893b5;
}
.allItem{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}
.userIcon i{
    
    padding: 28px 20px 28px 20px;
    font-size: 20px;
    background-color: #efefef;
}
.scopic{
    font-size: 11px;
    font-weight: 600;
    color: #026ebf;
    margin-left: 8px;
}
.member{
    font-size: 10px;
    background-color: #efefef;
    padding: 6px 8px 6px 8px;
    font-weight: 600;
    text-transform: uppercase;
}
.USACus{
    margin-left: 8px;
    font-size: 10px;
    font-weight: 600;
}
.paragraphDiv a{
    font-size: 10px;
    text-decoration: none;
    background-color: #2549ef;
    padding: 5px;
    color: #fff;
    margin-left: 60px;
}
.custDoller{
    font-size: 10px;
    margin-left: 8px;
    background: #000;
    color: #fff;
    padding: 3px 6px;
    border-radius: 10px;
}
.customDoller{
    font-size: 10px;
}
.customPrice{
    font-size: 11px;
    font-weight: 600;
}
.customLike{
    font-size: 11px;
    font-weight: 600;
    margin-left: 10px;
}
.customHeart{
    font-size: 13px;
    margin-left: 60px;
}
.horizontalLine {
    width: 500px;
    height: 1px;
    background-color: #000;
    margin: 0px auto;
    margin-left: 10px;
}
.allItem1{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.imgSection img {
    width: 65px;
    height: 50px;
    margin-left: 18px;
    margin-top: 10px;
}
.custtext1{
    font-size: 10px;
    margin-left: 8px;
    font-weight: 600;
}
.custDoller1{
    font-size: 10px;
    font-weight: 600;
}
.custStart{
    font-size: 10px;
    font-weight: 600;
    margin-left: 12px;
}
.textSectioncus1 p {
    font-size: 8px;
    margin-left: 95px;
    margin-top: -35px;
}
.allAppitem{
    /* display: flex; */
    /* flex-wrap: wrap; 
}
/*.allAppitem a{
    text-decoration: none;
    font-size: 10px;
    border: 1px solid #000;
    padding: 5px;
    color: #000;
    border-radius: 5px;
}
.allportfolliowItem{
    width: 100%;
    height: auto;
    background-color: #febf62;
}
.allportfolliowItem{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.portfolioItem .portCusIcon{
    font-size: 12px;
}
.portfolioItem .cusMoreService{
    font-size: 12px;
    text-transform: uppercase;
}
.cusMoreService a{
    color: #000;
}
.CustomLine{
    width: 1px;
    height: 15px;
    background-color: #000;
    border: 1px solid #000;
    align-content: center;
    margin-left: 6px;
}






@media (max-width: 768px) {
        table {
            font-family: arial, sans-serif;
            border-collapse: collapse;
            width: 100%;
    }
    select#course {
        padding: 5px 10px 6px 10px;
        background: #d9d9d9;
    }
    .skillsSection3 a {
        padding: 5px;
        border: 1px solid #437090;
        background: #d9d9d9;
        padding: 5px 10px 6px 10px;
        text-decoration: none;
    }
    .skillsSection4 a {
        padding: 5px;
        border: 1px solid #437090;
        background: #026ebf;
        padding: 5px 10px 6px 10px;
        text-decoration: none;
        color: #fff;
    }
}

/*----------------------- Load More ____________*/

/*.lordMoreSection{
    width: 100%;
    height: auto;
}
.lordMoreSection1{
    display: flex;
}
.lordMoreSection1 a{
    padding: 12px 50px 12px 50px;
    border: 1px solid #2549ef;
    text-decoration: none;
    border-radius: 6px;
    color:#000;
    margin: 0px auto;
    margin-bottom: 10px;
    font-weight: 600;
}*/

